const IDEAL_BLIP_WIDTH = 22
const Blip = function (name, title,ring, isNew,ADR, topic, description) {
  var self, number

  self = {}
  number = -1

  self.width = IDEAL_BLIP_WIDTH

  self.name = function () {
    return name
  }

  self.title = function () {
    return title
  }

  self.topic = function () {
    return topic || ''
  }

  self.description = function () {
    return description || ''
  }

  self.isNew = function () {
    var newIs = false

    if (isNew) {
      if (isNew.toLowerCase() === 'true') {
        newIs = true
      }
    }

    return newIs
  }

  self.adr = function () {
    var adr = false

    if (ADR) {
      if (ADR.toLowerCase() === 'true') {
        adr = true
      }
    }

    return adr
  }

  self.ring = function () {
    return ring
  }

  self.number = function () {
    return number
  }

  self.setNumber = function (newNumber) {
    number = newNumber
  }

  return self
}

module.exports = Blip
