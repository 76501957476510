<template>
    <div class="w-full place-content-center">
        
        <router-link to="/" class="home-link">&#171; Back to Radar home</router-link>

        <div class="prose" v-html="content"> </div>
    </div>
</template>


<script>
import MarkdownIt from "markdown-it";

export default {
  name: "Principles",
  data: () => ({
    content: null
  }),
  created() {
    
    let url = '/content/principles.md'
    
    this.$http.get(url)
      .then( response  => {
        let md = new MarkdownIt({
          html: true,
          linkify: true,
          typographer: true
        })
        
        this.content = md.render(response.bodyText)
      })
      .catch(error => {

        if (error.status === 404){
          this.content = `<h2>Content Record Found</h2>`
        }
        console.log(error);
      })
  }
}

</script>