// Store parsed D3 json to Vue
export default {
  namespaced: true,
  state() {
    return {
      chartData: [],
    };
  },
  mutations: {
    setChartData(state, payload) {
      state.chartData = payload;
    },
  },
  actions: {
    setData(context, payload) {
      context.commit("setChartData", payload);
    },
  },
  getters: {
    data(state) {
      return state.chartData;
    },
  },
};
