<template>
  <div class="w-full">
    <Chart />
  </div>
</template>

<script>
import Chart from "@/components/Chart";
export default {
  name: "Home",
  components: { Chart },
};
</script>
