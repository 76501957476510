<template>
  <div id="navigation-container">
    <!-- Navigation Header -->
    <header
      class="fixed flex justify-between items-center shadow-lg h-16 w-full px-2 z-30 bg-primary"
    >
      <div class="flex items-center">
        <!-- Toggle Menu Button -->
        <button id="menuButton" class="btn btn--subtle lg:hidden" type="button">
          <img
            id="menuIcon"
            class="block"
            src="../assets/icons/menu.svg"
            alt="Menu"
          />
          <img
            id="closeIcon"
            class="hidden"
            src="../assets/icons/close.svg"
            alt="Close"
          />
        </button>
        <!-- Shipyard Logo -->
        <router-link
          to="/"
          id="logo"
          class="flex flex-shrink-0 items-center text-white"
        >
          <!-- <img
            class="h-16"
            src="../assets/icons/shipyard-logo--white.svg"
            alt="Shipyard Logo"
          /> -->
          <img
            class="h-8 pl-2 lg:pl-4 pr-4"
            src="../assets/icons/radar-logo--white.svg"
            alt="Radar Logo"
          />
          <span class="text-lg font-medium tracking-wider">
            Radar - Issue #2
          </span>
        </router-link>
      </div>
      <!-- Header Links -->
      <nav class="flex flex-grow justify-end">
        <ul class="list-none hidden lg:block p-0 m-0">
          <li class="inline-block">
            <router-link
              to="/principles"
              class="btn btn--subtle text-base font-normal hover:text-white text-gray-200"
            >
              Principles
            </router-link>
          </li>
          <li class="inline-block">
            <a
              href="https://shipyardtechnologyventures.atlassian.net/wiki/spaces/SHIP/pages/532807721/Software+Factory"
              target="_blank"
              class="btn btn--subtle text-base font-normal hover:text-white text-gray-200"
            >
              Factory
            </a>
          </li>
          <li class="inline-block">
            <router-link
              to="/about"
              class="btn btn--subtle text-base font-normal hover:text-white text-gray-200"
            >
              About
              <span
                class="absolute opacity-0 h-1 -bottom-3 inset-x-4 bg-white"
              ></span>
            </router-link>
          </li>
        </ul>
        <search-drop-down />
        <button id="searchButton" class="btn block" type="button">
          <img src="../assets/icons/search--white.svg" alt="Search" />
        </button>
      </nav>
    </header>
    <!-- Navigation Left Panel Links -->
    <aside
      id="leftSidePanel"
      class="fixed hidden bg-white border-r border-gray-300 top-16 bottom-0 w-64 z-20"
    >
      <ul id="leftSidePanelLinks" class="text-sm font-normal mt-4">
        <li>
          <router-link
            to="/principles"
            class="flex justify-between w-full h-full py-2 px-4 font-semibold hover:bg-gray-100"
          >
            Principles
          </router-link>
        </li>
        <li>
          <a
            href="https://shipyardtechnologyventures.atlassian.net/wiki/spaces/SHIP/pages/532807721/Software+Factory"
            target="_blank"
            class="flex justify-between w-full h-full py-2 px-4 font-semibold hover:bg-gray-100"
          >
            Factory
            <img
              class="inline-block"
              src="../assets/icons/launch.svg"
              alt="Launch"
            />
          </a>
        </li>
        <li>
          <router-link
            to="/about"
            class="flex justify-between w-full h-full py-2 px-4 font-semibold hover:bg-gray-100"
          >
            About
          </router-link>
        </li>
      </ul>
    </aside>
    <!-- Scrim -->
    <div
      id="scrim"
      class="fixed transition pointer-events-none opacity-0 bg-scrim bg-opacity-50 w-full h-full z-10"
    />
  </div>
</template>

<script>
import SearchDropDown from "./SearchDropDown.vue";

export default {
  name: "NavigationContainerVanilla",
  components: {
    SearchDropDown,
  },
  mounted() {
    /*
      Important:
      This vanilla JS has been moved into the mounted hook for Vue.
    */
    let panelOpen = false;
    const button = document.querySelector("#menuButton");
    const menu = document.querySelector("#menuIcon");
    const close = document.querySelector("#closeIcon");
    const panel = document.querySelector("#leftSidePanel");
    const scrim = document.querySelector("#scrim");
    const links = document.querySelector("#leftSidePanelLinks");
    const logo = document.querySelector("#logo");

    const toggleStyles = (toggleCondition, elementsList) => {
      elementsList.forEach((element) => {
        const { node, stylesWhenOpen, stylesWhenClosed } = element;
        if (toggleCondition) {
          node.classList.contains(...stylesWhenClosed) &&
            node.classList.remove(...stylesWhenClosed);
          node.classList.add(...stylesWhenOpen);
        } else {
          node.classList.contains(...stylesWhenOpen) &&
            node.classList.remove(...stylesWhenOpen);
          node.classList.add(...stylesWhenClosed);
        }
      });
    };

    const toggleAll = (closeAll = false) => {
      // Sometimes we want a click to always close the panel
      panelOpen = closeAll ? false : (panelOpen = !panelOpen);
      // Toggle visibility of text input
      if ((searchOpen === true) & (panelOpen === true)) {
        toggleInput(true);
      }
      // Toggle visibility of menu/close ../assets/icons
      toggleStyles(panelOpen, [
        {
          node: menu,
          stylesWhenOpen: ["hidden"],
          stylesWhenClosed: ["block"],
        },
        {
          node: close,
          stylesWhenOpen: ["block"],
          stylesWhenClosed: ["hidden"],
        },
      ]);
      // Toggle visibility of Left Side Panel
      toggleStyles(panelOpen, [
        {
          node: panel,
          stylesWhenOpen: ["lg:hidden", "block"],
          stylesWhenClosed: ["hidden"],
        },
      ]);
      // Toggle visibility of Scrim
      toggleStyles(panelOpen, [
        {
          node: scrim,
          stylesWhenOpen: [
            "lg:pointer-events-none",
            "lg:opacity-0",
            "pointer-events-auto",
            "opacity-100",
          ],
          stylesWhenClosed: ["pointer-events-none", "opacity-0"],
        },
      ]);
    };

    logo.addEventListener("click", () => toggleAll(true));
    button.addEventListener("click", () => toggleAll());
    links.addEventListener("click", () => toggleAll(true));
    scrim.addEventListener("click", () => toggleAll());

    /* Search Text Input */
    let searchOpen = false;
    const search = document.querySelector("#searchButton");
    const input = document.querySelector("#textInput");
    const closeSearch = document.querySelector("#closeSearchButton");
    const main = document.querySelector("#main");

    const toggleInput = (closeAll = false) => {
      searchOpen = closeAll ? false : (searchOpen = !searchOpen);
      if ((searchOpen === true) & (panelOpen === true)) {
        toggleAll(true);
      }
      toggleStyles(searchOpen, [
        {
          node: search,
          stylesWhenOpen: ["hidden"],
          stylesWhenClosed: ["block"],
        },
        {
          node: input,
          stylesWhenOpen: ["block"],
          stylesWhenClosed: ["hidden"],
        },
      ]);
      toggleStyles(searchOpen, [
        {
          node: logo,
          stylesWhenOpen: ["md:flex", "hidden"],
          stylesWhenClosed: ["flex"],
        },
      ]);
      if (searchOpen === true) {
        document.getElementById("searchInput").focus();
      }
    };

    search.addEventListener("click", () => toggleInput());
    closeSearch.addEventListener("click", () => toggleInput(true));
    main.addEventListener("click", () => toggleInput(true));
  },
};
</script>

<style>
/* Styling vue-router's active link class for header */
nav .router-link-active > span {
  @apply opacity-100;
}
/* And left panel */
aside .router-link-active {
  @apply font-semibold text-primary bg-gray-200;
}
</style>
