<template>
  <div class="w-full place-content-center">

    <router-link to="/" class="home-link">&#171; Back to Radar home</router-link>

    <div class="prose" v-html="content"> </div>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";

let md = new MarkdownIt()

export default {
  name: "About",
  data: () => ({
    content: null
  }),
  created() {
    this.$http.get("content/about.md").then( data  => this.content = md.render(data.bodyText))
  }
}
</script>