<template>
  <div id="textInput" class="hidden flex flex-grow relative md:flex-none ml-2">
    <div class="flex flex-grow z-10">
      <input
        id="searchInput"
        v-model="searchValue"
        v-on:keyup.enter="submit"
        name="searchInput"
        type="text"
        placeholder="Search"
        autocomplete="off"
        class="text-input text-input--search text-input--trailing-icon flex flex-grow"
      />
      <button
        id="closeSearchButton"
        type="button btn--subtle"
        class="flex items-center justify-center pointer relative bg-none right-10 p-0 w-10 -mr-10"
      >
        <img src="../assets/icons/close--grey.svg" alt="close" />
      </button>
    </div>
    <ul
      id="searchList"
      @keyup="nextItem($event)"
      v-if="matches && matches.length"
      :class="matchesState"
      class="absolute list-none box-border border-t-0 border-gray-300 inset-x-0 top-10 bg-gray-50 shadow"
    >
      <li
        v-for="item in matches"
        :key="matches.indexOf(item)"
        @click="clickItem(matches.indexOf(item))"
      >
        <span
          :class="{ 'bg-gray-200': currentMatch === matches.indexOf(item) }"
          tabindex="0"
          class="block overflow-hidden whitespace-nowrap overflow-ellipsis cursor-pointer font-medium text-sm px-4 py-1.5 hover:bg-gray-200"
        >
          {{ item.title }}
          <span class="lowercase text-xs font-light text-gray-600">
            - {{ item.description }}
          </span>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SearchDropDown",
  data() {
    return {
      chartData: this.$store.getters["chart/data"],
      matches: [],
      searchValue: "",
      currentMatch: 0,
    };
  },
  mounted() {
    document.addEventListener("keyup", this.nextItem);
  },
  methods: {
    // Navigate up & down search results <ul>.
    nextItem(event) {
      const length = this.matches.length - 1;
      switch (event.key) {
        case "ArrowUp":
          if (this.currentMatch === 0) {
            this.currentMatch = length;
            break;
          } else if (this.currentMatch > 0) {
            this.currentMatch--;
            break;
          }
          break;
        case "ArrowDown":
          if (this.currentMatch === length) {
            this.currentMatch = 0;
            break;
          } else if (this.currentMatch < length) {
            this.currentMatch++;
            break;
          }
          break;
      }
    },
    // Click a result <li> & submit.
    clickItem(index) {
      this.currentMatch = index;
      this.submit();
    },
    // Send searched selected blip to the chart.
    submit() {
      if (
        this.$route.path !==
          `/search/${this.matches[this.currentMatch].name}` &&
        this.matches
      ) {
        this.$router.push({
          name: "search",
          params: {
            name: this.matches[this.currentMatch].name,
            match: this.matches[this.currentMatch],
          },
        });
        this.searchValue = "";
        this.matches = "";
      }
    },
  },
  computed: {
    // Collapse drop down styles when matches is empty.
    matchesState() {
      if (!this.matches?.length) {
        return ["border-0", "p-0"];
      } else {
        return ["border-2", "py-4"];
      }
    },
  },
  watch: {
    // Watch v-model of this.searchValue & repopulate matches.
    searchValue() {
      this.currentMatch = 0;
      if (this.searchValue) {
        this.matches = this.chartData.filter((blip) => {
          return blip.title
            .toUpperCase()
            .startsWith(this.searchValue.toUpperCase());
        });
      }
    },
    // Watch $route to remove d3-tip when navigating to different routes.
    $route() {
      if (this.$route.path !== "/") {
        const event = new CustomEvent("hideTip");
        document.dispatchEvent(event);
      }
    },
  },
};
</script>
