export const mockConfig = {
  mockAuth: true
}

export const oidcSettings = {
  authority: '/auth/realms/factory',
  client_id: 'factory',
  redirect_uri: window.location.origin + '/oidc-callback',
  response_type: 'id_token token',
  scope: 'openid profile',
  automaticSilentRenew: true,
  automaticSilentSignin: false,
  post_logout_redirect_uri: window.location.origin,
  revokeAccessTokenOnSignout: true,
  silentRedirectUri: window.location.origin + '/silent-renew-oidc.html'
}

// {
//     "authority": "https://accounts.google.com",
//     "clientId": "459300396575-3ruj8l8jn69pcgst8rgkqnk6g43gbc78.apps.googleusercontent.com",
//     "redirectUri": "http://localhost:5002/oidc-callback",
//     "popupRedirectUri": "http://localhost:5002/oidc-popup-callback",
//     "responseType": "id_token token",
//     "scope": "openid email",
//     "automaticSilentRenew": true,
//     "automaticSilentSignin": false,
//     "silentRedirectUri": "http://localhost:5002/silent-renew-oidc.html"
// }
