<template>
    <div class="w-full place-content-center">
        
        <router-link to="/" class="home-link">&#171; Back to Radar home</router-link>
        
        <div class="mt-1 mb-3 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
          <div class="mt-2 flex items-center text-sm text-gray-500">
            Publish Date: {{ moment(publish_date,'YYYY-MM-DD').format('YYYY-MM-DD') }}
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500">
            Status: {{ status }}
          </div>
        </div>
        
        <div class="prose" v-html="content"> </div>
    </div>
</template>

<script>
import MarkdownIt from "markdown-it";
import fm from 'markdown-it-front-matter';
import yaml from 'js-yaml';
import moment from 'moment';

export default {
  name: "Adr",
  data: () => ({
    content: null,
    tags: [],
    publish_date: null,
    title : null,
    status : null
  }),
  created() {
    
    let record = this.$route.params.name
    let url = '/content/radar/' + record + '.md'
    
    this.$http.get(url)
      .then( response  => {
        let md = new MarkdownIt({
          html: true,
          linkify: true,
          typographer: true
        }).use(fm,fm => {
            const doc = yaml.load(fm)
            this.tags = doc.tags
            this.publish_date = doc.publish_date
            this.status = doc.status  
        })
        
        this.content = md.render(response.bodyText)
      })
      .catch(error => {

        if (error.status === 404){
          this.content = `<h2>No Architecture Record Found</h2>`
        }
        console.log(error);
      })
  },
  methods : {
    moment
  }
}

</script>