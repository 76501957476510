import { Ability } from '@casl/ability'
// import { mockConfig } from '../config'

export const ability = new Ability()

export const abilityPlugin = store => {
  if (store.state.rules) {
    ability.update(store.state.rules)
  }

  store.watch(
    state => state.authentication,
    () => {
      updateRoles(store)
    }
  )
  return store.subscribe(mutation => {
    switch (mutation.type) {
      case 'setOidcAuth':
        updateRoles(store)
        break
      case 'unsetOidcAuth':
        updateRoles(store)
        break
    }
  })
}

const updateRoles = store => {
  if (store.state.authentication.user) {
    var roles = store.state.authentication.user.authorities
    console.log('UPDATING ROLES')
    console.log(store.state.authentication.user)
    if (roles == null) {
      roles = []
    }
    // if (roles.indexOf('role_smart_service') >= 0) {
    //   ability.update(store.state.roles.permissionsForSmartServices)
    // }
    // if (roles.indexOf('role_companies') >= 0) {
    //   console.log(store.state.roles.permissionsForCompanies)
    //   ability.update([...store.state.roles.permissionsForCompanies])
    //   console.log(ability)
    // }
    // if (roles.indexOf('role_admin') >= 0) {
    //   ability.update([
    //     ...store.state.roles.permissionsForSmartServices,
    //     ...store.state.roles.permissionsForCompanies
    //   ])
    // }
    // if (roles.indexOf('role_super_admin') >= 0) {
    //   ability.update([
    //     ...store.state.roles.permissionsForSmartServices,
    //     ...store.state.roles.permissionsForCompanies,
    //     ...store.state.roles.permissionsForSuperAdmin
    //   ])
    // }
  } else {
    ability.update([])
  }
  // if (mockConfig.mockAuth === true) {
  //   ability.update(store.state.roles.permissionsForSmartServices)
  //   ability.update([...store.state.roles.permissionsForCompanies])
  //   ability.update([
  //     ...store.state.roles.permissionsForSmartServices,
  //     ...store.state.roles.permissionsForCompanies
  //   ])
  // }
}
