<template>
  <div>
    <div id="toolbar"></div>
    <div id="plot"></div>
  </div>
</template>

<script>
import DataFactory from "../radar";

export default {
  name: "Chart",
  async mounted() {
    const factory = new DataFactory();
    // Wait for chart to render before dispatching events to D3.
    await factory.build().then((parsedCsv) => {
      this.$store.dispatch("chart/setData", parsedCsv);
    });
    this.goToBlip();
  },
  watch: {
    /*
     * If the route that renders Chart is `/search/${params}`.
     * Call goToBlip() to dispatch data to d3 via custom event.
     */
    $route() {
      if (this.$route.path.includes("search")) {
        this.goToBlip();
      }
    },
  },
  methods: {
    // Dispatch a custom event passing the matched Blip data to D3
    goToBlip() {
      if (this.$route.params.match) {
        const event = new CustomEvent("searchChart", {
          detail: this.$route.params.match,
        });
        document.dispatchEvent(event);
      }
    },
  },
};
</script>
