import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Adr from "../views/Adr.vue";
import Principles from "../views/Principles.vue";
import OidcCallback from "@/views/OidcCallback.vue";
import OidcPopupCallback from "@/views/OidcPopupCallback.vue";
import OidcCallbackError from "@/views/OidcCallbackError.vue";
// import { store } from "@/store";
// import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      isPublic: false,
    },
  },
  {
    path: "/search/:name",
    name: "search",
    component: Home,
    meta: {
      isPublic: false,
    },
    props: true,
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: {
      isPublic: false,
    },
  },
  {
    path: "/principles",
    name: "principles",
    component: Principles,
    meta: {
      isPublic: false,
    },
  },
  
  {
    path: "/adr/:name",
    name: "adr",
    component: Adr,
    meta: {
      isPublic: false,
    },
    props: true,
  },

  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true,
    },
  },
  {
    path: "/oidc-popup-callback", // Needs to match popupRedirectUri in you oidcSettings
    name: "oidcPopupCallback",
    component: OidcPopupCallback,
  },
  {
    path: "/oidc-callback-error", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallbackError",
    component: OidcCallbackError,
    meta: {
      isPublic: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

export default router;
