/* eslint no-constant-condition: "off" */

import * as d3 from "d3";

// const InputSanitizer = require('./inputSanitizer')
import Radar from "../models/radar";
import Quadrant from "../models/quadrant";
import Ring from "../models/ring";
import Blip from "../models/blip";
import GraphingRadar from "../graphing/radar";
import ExceptionMessages from "../exceptions/exceptionMessages";
import ContentValidator from "./contentValidator";


const ringConfig = {
    "Adopt" : 0,
    "Hold" :  1,
    "Trial" : 2,
    "Assess" : 3
}

const _ = {
  map: require("lodash/map"),
  uniqBy: require("lodash/uniqBy"),
  capitalize: require("lodash/capitalize"),
  each: require("lodash/each"),
};

const plotRadar = function(blips) {
  var rings = _.map(_.uniqBy(blips, "ring"), "ring");
  var ringMap = {};
  var maxRings = 4;

  _.each(rings, function(ringName, i) {
    if (i === maxRings) {
      throw ExceptionMessages.TOO_MANY_RINGS;
    }
  });

  _.each(ringConfig,function(order,name){

    ringMap[name] = new Ring(name, order);
  })

  var quadrants = {};
  _.each(blips, function(blip) {
    if (!quadrants[blip.quadrant]) {
      quadrants[blip.quadrant] = new Quadrant(_.capitalize(blip.quadrant));
    }
    quadrants[blip.quadrant].add(
      new Blip(
        blip.name,
        blip.title,
        ringMap[blip.ring],
        blip.isNew,
        blip.ADR,
        blip.topic,
        blip.description
      )
    );
  });

  var radar = new Radar();
  _.each(quadrants, function(quadrant) {
    radar.addQuadrant(quadrant);
  });

  var size = window.innerHeight - 133 < 620 ? 620 : window.innerHeight - 133;

  var graphingRadar = new GraphingRadar(size, radar);

  graphingRadar.init().plot();
};

const CSVDocument = function(url) {
  var self = {};

  self.build = function() {
    // return parsed D3 json to Vue
    return d3.csv(url).then(createBlips);
  };

  var createBlips = function(data) {
    try {
      var columnNames = data.columns;
      delete data.columns;
      var contentValidator = new ContentValidator(columnNames);
      contentValidator.verifyContent();
      contentValidator.verifyHeaders();
      plotRadar(data);
      return data;
    } catch (exception) {
      console.log(exception);
    }
  };

  self.init = function() {
    return self;
  };

  return self;
};

const DataFactory = function() {
  var self = {};
  var sheet;
  let data;

  self.build = async function() {
    console.log("Build Radar");

    var origin = window.location.origin;

    sheet = CSVDocument(origin + "/radar-export.csv");
    data = await sheet.init().build();
    // return parsed D3 json to Vue
    return data;
  };

  return self;
};

export default DataFactory;
