import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings } from "../config";

import { abilityPlugin, ability as appAbility } from "./ability";
import chartModule from "./chartData";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export const ability = appAbility;

export const store = new Vuex.Store({
  modules: {
    // Store parsed D3 json to Vue
    chart: chartModule,
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      // Optional OIDC store settings
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      // Optional OIDC event listeners
      {
        userLoaded: (user) => console.log("OIDC user is loaded:", user),
        userUnloaded: () => console.log("OIDC user is unloaded"),
        accessTokenExpiring: () => console.log("Access token will expire"),
        accessTokenExpired: () => console.log("Access token did expire"),
        silentRenewError: () => console.log("OIDC user is unloaded"),
        userSignedOut: () => console.log("OIDC user is signed out"),
        oidcError: (payload) => console.log("OIDC error", payload),
        automaticSilentRenewError: (payload) =>
          console.log("OIDC automaticSilentRenewError", payload),
      }
    ),
  },
  plugins: [abilityPlugin, vuexLocalStorage.plugin],
});
