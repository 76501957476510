<template>
  <div id="app">
    <navigation-container-vanilla />
    <main id="main" class="relative top-16 pt-4">
      <div class="container flex mx-auto px-4">
        <router-view />
      </div>
    </main>
    <!-- Content Area -->
  </div>
</template>

<script>
import "./assets/css/radar/base.scss";
import NavigationContainerVanilla from "@/components/NavigationContainerVanilla.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  components: { NavigationContainerVanilla },
  computed: {
    ...mapGetters("oidcStore", ["oidcIsAuthenticated"]),
    hasAccess: function() {
      return this.oidcIsAuthenticated || this.$route.meta.isPublic;
    },
  },
  mounted() {
    window.addEventListener("vuexoidc:userLoaded", this.userLoaded);
    window.addEventListener("vuexoidc:oidcError", this.oidcError);
    window.addEventListener(
      "vuexoidc:automaticSilentRenewError",
      this.automaticSilentRenewError
    );
  },
  destroyed() {
    window.removeEventListener("vuexoidc:userLoaded", this.userLoaded);
    window.removeEventListener("vuexoidc:oidcError", this.oidcError);
    window.removeEventListener(
      "vuexoidc:automaticSilentRenewError",
      this.automaticSilentRenewError
    );
  },
  methods: {
    ...mapActions("oidcStore", ["authenticateOidcPopup", "removeOidcUser"]),
    userLoaded: function(e) {
      console.log(
        "I am listening to the user loaded event in vuex-oidc",
        e.detail
      );
    },
    oidcError: function(e) {
      console.log(
        "I am listening to the oidc oidcError event in vuex-oidc",
        e.detail
      );
    },
    automaticSilentRenewError: function(e) {
      console.log(
        "I am listening to the automaticSilentRenewError event in vuex-oidc",
        e.detail
      );
    },
    signOut: function() {
      this.removeOidcUser().then(() => {
        this.$router.push("/");
      });
    },
  },
  oidcError: function(e) {
    console.log(
      "I am listening to the oidc oidcError event in vuex-oidc",
      e.detail
    );
  },
  automaticSilentRenewError: function(e) {
    console.log(
      "I am listening to the automaticSilentRenewError event in vuex-oidc",
      e.detail
    );
  },
};
</script>
